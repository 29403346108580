<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
        <button class="button is-pulled-right is-small is-turquoise"
          @click="gotoV1">
          <span class="icon">
            <i class="mdi mdi-arrow-right" />
          </span>
          <span>V1</span>
        </button>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Invoice No"
                v-model="innerValue.invoiceNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Creditor"
                v-model="innerValue.creditor"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '00:00:00' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedToDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '23:59:59' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>

    </aside>
  </app-side-menu>
</template>
<script>
import AppSideMenu from '@/components/AppSideMenu'
import { mask } from 'vue-the-mask'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PaymentListListSideMenu',
  components: {
    AppSideMenu
  },
  directives: {
    mask
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedActive: 1,
      stateList: null,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    selectedFromDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateFrom = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.dateFrom = ''
      }
    },
    selectedToDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateTo = this.selectedToDate.toISOString()
      } else {
        this.innerValue.dateTo = ''
      }
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {
    if (this.innerValue.dateFrom) {
      this.dateFromFilter = new Date(this.innerValue.dateFrom).toLocaleDateString(this.$userInfo.locale)
      this.selectedFromDate = new Date(this.value.dateFrom)
    }
    if (this.innerValue.dateTo) {
      this.dateToFilter = new Date(this.innerValue.dateTo).toLocaleDateString(this.$userInfo.locale)
      this.selectedToDate = new Date(this.value.dateTo)
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onFilterClick() {
      this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
      this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = null
      this.selectedToDate = null
      this.$emit('reset')
    },
    gotoV1() {
      window.open(`${process.env.VUE_APP_ROOT_URI}/Modules/CreditorView/Payment/PaymentList.aspx`, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
